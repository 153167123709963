@import '_variables.scss';
@import '_mixins.scss';

.mt-10 {
  margin-top: 10px;
}

.mr-10 {
  margin-right: 10px;
}

.mb-10 {
  margin-bottom: 10px;
}

.ml-10 {
  margin-left: 10px;
}

.mb-5 {
  margin-bottom: 5px;
}

.mr-5 {
  margin-right: 5px;
}

.mb-20 {
  margin-bottom: 20px;
}

.mr-20 {
  margin-right: 20px;
}

.mb-30 {
  margin-bottom: 30px;
}

.mr-30 {
  margin-right: 30px;
}

.pzero {
  padding: 0px;
}

.icon-block {
  display: block;
}
