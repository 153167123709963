body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

:root {
  --primary-font-stack: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;

  /* // button color */
  --primary-color: #635bff;
  --primary-hover-color: #342bea;
  --secondary-color: #ffffff;
  --secondary-border-color: #e2e2e2;

  /* // text color */
  --text-color: #ffffff;
  --black-color: #000000;
  --header-title-color: #070707;
  --black-light-color: #393945;
  --grey-color: #81878e;
  --red-color: #ff4b4b;
  --outline-blue-color: #cfd5f5;
  --green-color: #0fbb48;
  --success-message: #f2ffe3;
  --error-message: #ffebeb;
  --background-color: #f9f9fa;
  --placeholder-color: #b5b7be;
  --background-hover-color: #eeeeee;
  --cart-text-color: #f58b3f;
  --ready-to-ship-color: #e6f8ed;
  --out-ot-stock-color: #ffeeee;
  /* // dropdown box and hover and tabs color */
  --background-dropdown: #f7f7f7;
  --background-image: #f7fafc;
  --annocement-color: #f4f6f8;

  /* // border color */
  --border-color: #f2f2f2;
  --input-border-hover-color: #c5c8cd;
  --box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.08);
}
