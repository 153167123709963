// --- Theme Context
$Primary_clr: #ffffff;
$Secondary_clr: #635bff;
$White_clr: #ffffff;
$Gray_Primary_clr: rgba(255, 255, 255, 0.5);
$Gray_Secondary_clr: rgba(255, 255, 255, 0.1);

// --- Button => Primary, Secondary
$Primary_btn_bg_clr: #635bff;
$Primary_btn_border_clr: $Secondary_clr;
$Primary_btn_txt_clr: #635bff;
$Primary_btn_icon_clr: $Primary_clr;
$Primary_btn_shadow_clr: 0px 1px 3px rgba(0, 0, 0, 0.12);
$Secondary_btn_bg_clr: rgba(255, 255, 255, 1);
$Secondary_btn_border_clr: 1px solid rgba(0, 0, 0, 0.1);
$Secondary_btn_txt_clr: #393945;
$Secondary_btn_icon_clr: rgba(255, 255, 255, 0.5);
$Secondary_btn_shadow_clr: 0px 1px 3px rgba(0, 0, 0, 0.12);

// --- Background => Primary, Secondary
$Primary_background_clr: $Primary_clr;
$Secondary_background_clr: rgba(255, 255, 255, 0.5);
$SimualGray_bg_clr: rgba(255, 255, 255, 0.1);
$Lightgray_bg_clr: rgba(255, 255, 255, 0.05);
$GrayShadow_bg_clr: #2a2b34;

// --- Text => Primary, Secondary
$Primary_text_clr: #393945;
$Secondary_text_clr: #4f566b;
$Theme_text_clr: $Secondary_clr;

// --- Border
$Primary_border_clr: #e3e8ee;

:export {
  // --- Theme Context
  Primary_clr: $Primary_clr;
  Secondary_clr: $Secondary_clr;
  White_clr: $White_clr;
  Gray_Primary_clr: $Gray_Primary_clr;
  Gray_Secondary_clr: $Gray_Secondary_clr;

  // --- Button => Primary, Secondary
  Primary_btn_border_clr: $Primary_btn_border_clr;
  Primary_btn_txt_clr: $Primary_btn_txt_clr;
  Primary_btn_icon_clr: $Primary_btn_icon_clr;
  Primary_btn_shadow_clr: $Primary_btn_shadow_clr;
  Secondary_btn_bg_clr: $Secondary_btn_bg_clr;
  Secondary_btn_border_clr: $Secondary_btn_border_clr;
  Secondary_btn_txt_clr: $Secondary_btn_txt_clr;
  Secondary_btn_icon_clr: $Secondary_btn_icon_clr;
  Secondary_btn_shadow_clr: $Secondary_btn_shadow_clr;

  // --- Background => Primary, Secondary
  Primary_background_clr: $Primary_background_clr;
  Secondary_background_clr: $Secondary_background_clr;
  SimualGray_bg_clr: $SimualGray_bg_clr;
  Lightgray_bg_clr: $Lightgray_bg_clr;
  GrayShadow_bg_clr: $GrayShadow_bg_clr;

  // --- Text => Primary, Secondary
  Primary_text_clr: $Primary_text_clr;
  Secondary_text_clr: $Secondary_text_clr;
  Theme_text_clr: $Theme_text_clr;

  // --- Border
  Primary_border_clr: $Primary_border_clr;
}
