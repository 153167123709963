@import '../scss/variables';
@import '../scss/_colors.scss';
@import 'mixins.scss';

@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700;800;900&display=swap');

html {
  scroll-behavior: smooth;
}

@media (prefers-reduced-motion: reduce) {
  html {
    scroll-behavior: auto;
  }
}

*,
::after,
::before {
  box-sizing: border-box;
}

html,
body,
#root {
  height: 100%;
}

body {
  font-family: $font_system;
  overflow: hidden;
  border: none;
}

h1,
h2,
h3 {
  font-weight: 700;
}

a {
  text-decoration: none;
}

button {
  font-family: $font_system;

  &:focus {
    outline: none;
  }
}

form {
  outline: none;
}

table {
  border-spacing: 0px;
  width: 100%;
}

.text-capitalize {
  text-transform: capitalize;
}

.MuiSelect-select:focus {
  background-color: transparent;
}

// pagination dropdown
#menu- .MuiMenu-paper {
  background-color: var(--secondary-color);
  box-shadow: var(--box-shadow), var(--box-shadow);
  border-radius: 2px;
  margin-top: -30px;
  margin-bottom: 0px;
  padding: 4px 0;
  border: 1px solid var(--secondary-border-color);
}

#menu- .MuiList-padding li {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  color: var(--black-light-color);
  background-color: var(--secondary-color);
  padding: 8px 12px;
}

#menu- .MuiList-padding li:hover {
  background-color: var(--primary-color);
  color: var(--text-color);
}

#menu- .MuiList-padding li.Mui-selected {
  background-color: var(--primary-hover-color);
  color: var(--text-color);
}

//
.MuiPopover-root .MuiList-padding {
  padding: 0;
}

.MuiPopover-root .MuiList-padding li {
  background-color: transparent;
  min-height: auto;
}

#customized-menu .MuiList-padding li {
  padding: 0px;
}

#customized-menu span:focus {
  outline: none;
}

.MuiPopover-root .MuiList-padding li div {
  margin: 0px;
}

.MuiPopover-root .MuiList-padding li div span {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  color: var(--black-light-color);
  padding: 7px 10px;
  margin: 1px 0;
  border-radius: 4px;
  text-transform: capitalize;
}

.MuiPopover-root .MuiList-padding li div span:hover {
  color: var(--primary-color);
  transition: all 0.3s;
}

#simple-menu .MuiPaper-root ul {
  padding: 6px;
  width: auto;
}

#simple-menu .MuiPaper-root {
  border: 1px solid var(--secondary-border-color);
  box-shadow: var(--box-shadow);
  border-radius: 6px;
  margin-top: 7px;
}

#simple-menu .MuiPaper-root ul li {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  color: var(--black-light-color);
  padding: 7px 10px;
  margin: 1px 0;
  border-radius: 4px;
  text-transform: capitalize;
}

#simple-menu .MuiPaper-root ul li:hover {
  color: var(--primary-color);
  background: var(--background-dropdown);
  transition: all 0.3s;
}

#simple-menu-2 .MuiPaper-root ul {
  padding: 6px;
  width: auto;
}

#simple-menu-2 .MuiPaper-root {
  border: 1px solid var(--secondary-border-color);
  box-shadow: var(--box-shadow);
  border-radius: 6px;
  margin-top: 7px;
}

#simple-menu-2 .MuiPaper-root ul li {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  color: var(--black-light-color);
  padding: 7px 10px;
  margin: 1px 0;
  border-radius: 4px;
  text-transform: capitalize;
}

#simple-menu-2 .MuiPaper-root ul li:hover {
  color: var(--primary-color);
  transition: all 0.3s;
  background: var(--background-dropdown);
}

#simple-menu-top .MuiPaper-root ul {
  padding: 10px;
  width: 107px;
}

#simple-menu-top .MuiPaper-root {
  box-shadow: var(--box-shadow), var(--box-shadow);
  border-radius: 6px;
  margin-top: 10px;
}

#simple-menu-top .MuiPaper-root ul li {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 18px;
  color: var(--black-light-color);
  padding: 10px;
}

#simple-menu-top .MuiPaper-root ul li:hover {
  color: var(--primary-color);
  transition: all 0.3s;
}

.journal-ul .MuiPaper-root {
  box-shadow: var(--box-shadow), var(--box-shadow);
  border-radius: 6px;
  margin-top: 10px;
}

.journal-ul .MuiPaper-root ul {
  padding: 10px;
  width: 189px;
}

.transactions-ul .MuiPaper-root ul {
  padding: 10px;
  width: auto;
}

.journal-ul .MuiPaper-root ul li {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 18px;
  color: var(--black-light-color);
  padding: 10px;
}

.journal-ul .MuiPaper-root ul li:hover {
  color: var(--primary-color);
  transition: all 0.3s;
}

.import-ul .MuiPaper-root {
  box-shadow: var(--box-shadow), var(--box-shadow);
  border-radius: 6px;
  margin-top: 10px;
}

.import-ul .MuiPaper-root ul {
  padding: 10px;
  width: 107px;
}

.import-ul .MuiPaper-root ul li {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 18px;
  color: var(--black-light-color);
  padding: 10px;
}

.import-ul .MuiPaper-root ul li:hover {
  color: var(--primary-color);
  transition: all 0.3s;
}

.MuiSelect-root.MuiSelect-select.MuiSelect-selectMenu.MuiInputBase-input.MuiInput-input {
  position: relative;
  background-color: transparent;
  padding-left: 8px;
  padding-top: 4px;
  padding-bottom: 4px;
}

.MuiSelect-root.MuiSelect-select.MuiSelect-selectMenu.MuiInputBase-input.MuiInput-input::before {
  content: '';
  position: absolute;
  right: 8px;
  background-image: url(../images/svg/inputicon.svg);
  width: 12px;
  height: 12px;
  background-repeat: no-repeat;
  top: calc(50% - 6px);
}

.SearchIcon {
  position: absolute;
  top: 50%;
  left: 15px;
  transform: translateY(-50%);
  color: var(--black-light-color);
}

.makeStyles-modal-2 .MuiBackdrop-root {
  background-color: var(--box-shadow);
}

.MuiInput-root .MuiSelect-icon {
  display: none;
}

.trpadding td:first-child {
  padding: 12px 0 12px 22px;
}

.trtotal td {
  font-family: 'Inter';
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 16px;
  color: var(--grey-color);
}

.trtotal td {
  padding: 12px 16px 12px 22px;
}

.trtotal td:last-child {
  padding: 12px 16px 12px;
}

.MuiTab-textColorPrimary .MuiTab-wrapper {
  height: 100%;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
}

.MuiTabs-flexContainer {
  height: 100%;
}

.MuiTab-textColorPrimary {
  padding: 0;
  height: 100%;
}

.MuiTabs-flexContainer .MuiTab-labelIcon {
  min-height: 27px;
  padding: 0;
  min-width: 100px;
  padding: 7px 16px;
}

.MuiTabs-flexContainer .MuiTab-labelIcon .MuiTab-wrapper svg {
  margin: 0 5px 3px 0;
}

.MuiTabs-flexContainer .MuiTab-textColorPrimary.Mui-selected svg {
  path {
    fill: var(--black-light-color);
    fill-opacity: 1;
  }
}

header .MuiTabs-root {
  min-height: 100%;
}

.MuiTabs-flexContainer .MuiTab-textColorPrimary.Mui-selected {
  color: var(--black-light-color);
  background: var(--secondary-color);
  box-shadow: var(--box-shadow);
  border-radius: 7px;
  min-width: 100px;
}

.MuiTabs-flexContainer .MuiTab-textColorPrimary {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 17px;
  text-align: center;
  color: var(--grey-color);
  text-transform: unset;
  padding: 7px 16px;
  height: 100%;
  min-height: 27px;
  min-width: 100px;
}

.MuiTabs-scrollable .MuiTabs-indicator {
  background-color: transparent;
}

.MuiTabScrollButton-root.Mui-disabled {
  display: none;
}

//
.MuiGrid-root .MuiFormControl-marginNormal {
  margin-top: 0;
  margin-bottom: 0;
  border: 1px solid var(--secondary-border-color);
  padding: 8px 10px 8px 14px;
  border-radius: 4px;
}

.MuiGrid-root .MuiFormControl-marginNormal:hover {
  border: 1px solid var(--input-border-hover-color);
}

.MuiGrid-root .MuiFormControl-marginNormal .MuiInput-root {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 20px;
  color: var(--black-light-color);
}

.MuiFormControl-marginNormal input {
  padding: 0;
}

.MuiFormControl-marginNormal .MuiIconButton-root {
  padding: 0;
}

.MuiFormControl-marginNormal .MuiInput-underline:before {
  border-bottom: 0px;
}

.MuiFormControl-marginNormal .MuiInput-underline:hover:not(.Mui-disabled):before {
  border-bottom: 0px;
}

.MuiFormControl-marginNormal .MuiInput-underline:after {
  border-bottom: 0px;
}

.MuiFormControl-marginNormal .MuiFormHelperText-root.Mui-error {
  bottom: -20px;
  position: absolute;
}

.modal {
  width: 100%;
  max-width: 450px;
  margin-left: auto;
  outline: 0;
}

.modallarge {
  width: 100%;
  max-width: 1200px;
  margin-left: auto;
  outline: 0;
}

.paper {
  background-color: var(--secondary-color);
  outline: 0;
  height: 100%;
  overflow: auto;
}

.modalsmall {
  width: 100%;
  max-width: 860px;
  margin-left: auto;
  outline: 0;
}

.gridblock .MuiFormControl-marginNormal {
  width: 100%;
}

.modalmedium {
  width: 860px;
  height: 100vh;
  margin-left: auto;
}

.grid {
  display: flex;
  align-items: center;
}

.css-1rhbuit-multiValue .css-12jo7m5 {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 18px;
  border-right: 0px;
  border-radius: 2px;
  color: var(--black-light-color);
  background: var(--border-color);
  border: 1px solid var(--secondary-border-color);
}

.css-xb97g8 {
  border: 1px solid var(--secondary-border-color);
  background: var(--border-color);
  border-left: 0px;

  svg {
    color: var(--black-light-color);
  }

  &:hover {
    svg {
      color: var(--header-title-color);
    }
  }
}

.paper .btn-save-loading {
  display: block;
}

.btn-save-loading {
  position: relative;
  width: auto;
  vertical-align: top;
}

.btn-save-loading.loading::before {
  content: '';
  position: absolute;
  top: 50%;
  left: calc(50% - 2px);
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  display: block;
  width: 4px;
  height: 4px;
  border-radius: 50%;
  background: var(--secondary-color);
  z-index: 2;
  margin-top: 4px;
  -webkit-animation: 0.45s cubic-bezier(0, 0, 0.15, 1) infinite alternate ball;
  animation: 0.45s cubic-bezier(0, 0, 0.15, 1) infinite alternate ball;
  -webkit-animation-delay: 0.15s;
  animation-delay: 0.15s;
}

.btn-save-loading.loading button {
  cursor: wait;
  color: transparent;
  background-color: var(--primary-color);
}

.btn-save-loading.btn-red.loading button {
  background-color: var(--red-color);
}

.btn-save-loading.loading button:hover {
  cursor: wait;
}

.btn-save-loading.loading button::before {
  content: '';
  position: absolute;
  top: 50%;
  left: calc(50% - 2px);
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  display: block;
  width: 4px;
  height: 4px;
  border-radius: 50%;
  background: var(--secondary-color);
  z-index: 2;
  margin-top: 4px;
  -webkit-animation: 0.45s cubic-bezier(0, 0, 0.15, 1) infinite alternate ball;
  animation: 0.45s cubic-bezier(0, 0, 0.15, 1) infinite alternate ball;
  margin-left: -10px;
}

.btn-save-loading.loading button::after {
  content: '';
  position: absolute;
  top: 50%;
  left: calc(50% - 2px);
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  display: block;
  width: 4px;
  height: 4px;
  border-radius: 50%;
  background: var(--secondary-color);
  z-index: 2;
  margin-top: 4px;
  -webkit-animation: 0.45s cubic-bezier(0, 0, 0.15, 1) infinite alternate ball;
  animation: 0.45s cubic-bezier(0, 0, 0.15, 1) infinite alternate ball;
  margin-left: 10px;
  -webkit-animation-delay: 0.3s;
  animation-delay: 0.3s;
}

@-webkit-keyframes ball {
  from {
    -webkit-transform: translateY(0) scaleY(0.8);
    transform: translateY(0) scaleY(0.8);
  }
  to {
    -webkit-transform: translateY(-10px);
    transform: translateY(-10px);
  }
}

@keyframes ball {
  from {
    -webkit-transform: translateY(0) scaleY(0.8);
    transform: translateY(0) scaleY(0.8);
  }
  to {
    -webkit-transform: translateY(-10px);
    transform: translateY(-10px);
  }
}

.MuiFormControl-marginNormal .MuiIconButton-root .MuiIconButton-label .MuiSvgIcon-root {
  display: none;
}

.MuiFormControl-marginNormal .MuiIconButton-root .MuiIconButton-label {
  background-image: url(../images/svg/calendar.svg);
  height: 1rem;
  width: 1rem;
  background-repeat: no-repeat;
  background-position: center;
}

.MuiDialog-container.MuiDialog-scrollPaper {
  align-items: end;
}

.MuiPaper-root.MuiDialog-paper.MuiDialog-paperScrollPaper.MuiDialog-paperWidthSm.MuiPaper-elevation24.MuiPaper-rounded {
  margin: 0 auto;
  box-shadow: none;
  background: var(--secondary-color);
  width: 100%;
  max-width: 350px;
  border-radius: 6px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.MuiPaper-root.MuiDialog-paper.MuiDialog-paperScrollPaper {
  max-height: none;
}

.MuiPaper-root.MuiAccordion-root.MuiAccordion-rounded.MuiPaper-elevation1.MuiPaper-rounded {
  box-shadow: none;
  background-color: transparent;
}

#simple-menu .MuiPaper-root ul .delete {
  color: var(--red-color);
}

#simple-menu .MuiPaper-root ul .delete:hover {
  color: var(--red-color);
}

@keyframes pulse {
  0% {
    background-color: var(--secondary-border-color);
  }
  50% {
    background-color: var(--annocement-color);
  }
  100% {
    background-color: var(--secondary-border-color);
  }
}

.treeviewWrap .MuiTreeItem-iconContainer {
  width: 18px;
  height: 18px;
  margin-right: 0px;
  background-color: transparent;
}

.treeviewWrap .MuiTreeItem-root.Mui-selected > .MuiTreeItem-content .MuiTreeItem-label {
  background-color: transparent;
}

.treeviewWrap .MuiTreeItem-root .MuiTreeItem-content .MuiTreeItem-label {
  padding-left: 6px;
  background-color: transparent;
}

.treeviewWrap .MuiTreeItem-label:hover {
  background-color: transparent;
}

.treeviewWrap .MuiTreeItem-root.Mui-selected > .MuiTreeItem-content .MuiTreeItem-label:hover {
  background-color: transparent;
}

.treeviewWrap .MuiTreeItem-root.Mui-selected:focus > .MuiTreeItem-content .MuiTreeItem-label {
  background-color: var(--secondary-color);
}

.transactionremoveborder div {
  border: none;
}

.iconsvg {
  padding: 7px 12px;
}

.alert-modal .MuiPaper-root ul {
  width: 220px !important;
}
